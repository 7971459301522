<template>
  <div class="market">
    <van-sticky>
      <div class="header-wrap" :style="`background: url(${$AppData.config.backgroundImgUrl}) 0 0/100% auto no-repeat;`">
        <van-config-provider :theme-vars="themeVars">
          <van-nav-bar :title="pageTitle" left-arrow @click-left="goBack" :border="false">
            <template #right>
              <span class="coldDownText">{{coldDown}}</span>
            </template>
          </van-nav-bar>
        </van-config-provider>
        <div class="account-info">
          <div class="info-item">
            <div class="info-item-label">
              <p>{{$t('field.available')}}</p>
              <p>({{$AppData.config.appCoin}})</p>
            </div>
            <p class="info-item-text">{{accountInfo.balanceStr}}</p>
          </div>
          <div class="info-item">
            <div class="info-item-label">
              <p>{{$t('field.orderCredits')}}</p>
              <p>&emsp;</p>
            </div>
            <p class="info-item-text">{{accountInfo.contribution}}</p>
          </div>
          <div class="info-item">
            <div class="info-item-label">
              <p>{{$t('field.frozen')}}</p>
              <p>({{$AppData.config.appCoin}})</p>
            </div>
            <p class="info-item-text">{{accountInfo.freezeStr}}</p>
          </div>
        </div>
        <van-row class="filter-wrap">
          <van-col span="24" class="filter-lab">
            {{$t('title.priceFilter')}}
          </van-col>
          <van-col span="9">
            <van-field
              v-model="minAmount"
              size="middle"
              maxlength="32"
              type="number"
              @blur="inputBlur"
              clearable
              :placeholder="$t('placeholder.minPrice')"
            />
          </van-col>
          <van-col span="1" class="filter-symbol">-</van-col>
          <van-col span="9">
            <van-field
              v-model="maxAmount"
              size="middle"
              maxlength="32"
              type="number"
              @blur="inputBlur"
              clearable
              :placeholder="$t('placeholder.maxPrice')"
            />
          </van-col>
          <van-col span="5">
            <van-button class="amount-btn" type="primary" size="small" @click="refreshData">{{$t('button.submit')}}</van-button>
          </van-col>
        </van-row>
      </div>
    </van-sticky>
    <div class="order-list">
      <van-list v-if="dataList.length >0">
        <div  class="journal-block" v-for="item in dataList" :key="item.sn">
          <van-cell-group v-if="$AppData.config.themeType">
            <div class="journal-detail">
              <van-cell :title="$t('field.orderNumber')" :value="item.sn" class="journal-detail-row" />
              <van-cell :title="$t('field.amount')" :value="`${item.amountStr} ${$AppData.config.appCoin}`" class="journal-detail-row" />
              <van-cell :title="$t('field.income2')" :value="`${item.incomeStr} ${$AppData.config.appCoin}`" class="journal-detail-row" />
              <!-- <van-cell :title="$t('field.contribution')" :value="item.contribution" class="journal-detail-row" /> -->
            </div>
            <div class="btn-wrap">
              <van-button type="primary" round size="small" @click="handleTakingOrder(item)">{{$t('button.orderReceiving')}}</van-button>
            </div>
          </van-cell-group>
          <van-cell-group v-else>
            <van-cell :title="$t('field.orderNumber')" :value="item.sn" class="journal-title" value-class="journal-sn" />
            <div class="journal-detail">
              <van-cell :title="$t('field.amount')" :value="`${item.amountStr} ${$AppData.config.appCoin}`" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
              <van-cell :title="$t('field.income2')" :value="`${item.incomeStr} ${$AppData.config.appCoin}`" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
              <!-- <van-cell :title="$t('field.contribution')" :value="item.contribution" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" /> -->
            </div>
            <div class="btn-wrap">
              <van-button type="danger" plain round size="small" @click="handleTakingOrder(item)">{{$t('button.orderReceiving')}}</van-button>
            </div>
          </van-cell-group>
        </div>
      </van-list>
      <div v-else>
        <van-empty :description="$t('common.noData')"></van-empty>
      </div>
    </div>
    <van-action-sheet
      v-model:show="showActions"
      :actions="actions"
      :description="actionTitle"
      @select="onSelectAction"
      :close-on-click-overlay="false"
    />
  </div>
</template>

<script>
import i18n from '@/assets/lang/index';
import {
  queryAccountInfo,
  queryRechargePage,
  queryContibutionPage,
  queryOrderTakingList,
  takingOrder,
  queryOnlineServicePage
} from "@/api";

export default {
  setup() {
    const themeVars = {
      navBarBackgroundColor: 'transparent',
      navBarTitleTextColor: '#fff',
      navBarIconColor: '#fff',
    };
    return { themeVars };
  },
  data() {
    return {
      pageTitle: localStorage.getItem('pageName'),
      accountInfo: {},
      dataList: [],
      coldDown: 30,
      refreshInterval: false,
      showActions: false,
      actionTitle: '',
      actions: [],
      minAmount: null,
      maxAmount: null,
    };
  },
  
  mounted() {
    this.fetchAccountInfo(() => {
      if (this.accountInfo.balance <= 0) {
        this.notEnoughBalance();
      } else {
        this.fetchOrderList();
        this.refreshInterval = setInterval(() => {
          this.coldDown = this.coldDown - 1;
          if (this.coldDown === 0) {
            this.refreshData();
          }
        }, 1000);
      }
    });
  },
  
  unmounted() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  },

  methods: {
    fetchAccountInfo(callback) {
      queryAccountInfo().then(res => {
        this.accountInfo = res.data || {};
        callback && callback();
      });
    },
    fetchOrderList() {
      queryOrderTakingList({
        minAmount: this.minAmount,
        maxAmount: this.maxAmount
      }).then(res => {
        this.dataList = res.data || [];
      });
    },
    handleTakingOrder(order) {
      if (order.contribution) {
        this.$myDialog.confirm({
          title: i18n.global.t('alert.warnning'),
          message: `${i18n.global.t('alert.deductContribution')}[${order.contribution}]`,
        })
        .then(() => {// on confirm
          takingOrder({ sn: order.sn })
          .then(res => {
            localStorage.setItem('orderDetail', JSON.stringify(res.data));
            this.$router.push('/order/detail');
          })
          .catch(error => {
            if (error.status === 200) {
              this.refreshData();
              if (error.data.code === 'B0119') {
                this.notEnoughBalance();
              }
              if (error.data.code === 'S0711') {
                this.notEnoughContibution(error.data.msg);
              }
              if (error.data.code === 'B0400'){
                this.goToOnlineService(error.data.msg);
              }
            }
          });
        });
      } else {
        takingOrder({ sn: order.sn }).then(res => {
          localStorage.setItem('orderDetail', JSON.stringify(res.data));
          this.$router.push('/order/detail');
        }).catch(error => {
          if (error.status === 200) {
            this.refreshData();
            if (error.data.code === 'B0119') {
              this.notEnoughBalance();
            }
            if (error.data.code === 'B0400'){
              this.goToOnlineService(error.data.msg);
            }
          }
        });
      }
    },
    refreshData() {
      this.fetchOrderList();
      this.coldDown = 30;
    },
    notEnoughBalance() {
      queryRechargePage().then(res => {
        let pages = res.data.tempVOList || [];
        let actions = pages.map(item => ({ key: item.code, name: item.name }) );
        actions.push({ key: 'BACK', name: i18n.global.t('button.back'), color: '#ee0a24' });
        this.actionTitle = i18n.global.t('notify.insufficientAvailableBalance');
        this.actions = actions;
        this.showActions = true;
      });
    },
    goToOnlineService(errorMsg) {
      queryOnlineServicePage().then(res => {
        let pages = res.data.tempVOList || [];
        let actions = pages.map(item => ({ key: item.code, name: item.name }) );
        actions.push({ key: 'BACK', name: i18n.global.t('button.back'), color: '#ee0a24' });
        this.actionTitle = errorMsg;
        this.actions = actions;
        this.showActions = true;
      });
    },
    notEnoughContibution(errotMsg) {
      queryContibutionPage().then(res => {
        let pages = res.data.tempVOList || [];
        let actions = pages.map(item => ({ key: item.code, name: item.name }) );
        actions.push({ key: 'BACK', name: i18n.global.t('button.back'), color: '#ee0a24' });
        this.actionTitle = errotMsg;
        this.actions = actions;
        this.showActions = true;
      });
    },
    onSelectAction(item) {
      if(item.key == 'BACK') {
        if (this.accountInfo.balance <= 0) {
          this.goBack();
        } else {
         this.showActions = false;
        }
      } else {
        let page = this.$AppData.pageMapping[item.key];
        if (page) {
          localStorage.setItem('pageName', item.name);
          this.$router.replace(page);
        } else {
          this.goBack();
        }
      }
    },
    inputBlur() {
      let tempAmount;
      if ((this.minAmount && this.maxAmount) && parseFloat(this.minAmount) > parseFloat(this.maxAmount)) {
        tempAmount = this.minAmount;
        this.minAmount = this.maxAmount;
        this.maxAmount = tempAmount;
      }
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.market {
  min-height: 100vh;
  .header-wrap { min-height: 210px; }
  .coldDownText {
    font-size: 18px;
    color: red;
  }
  .account-info {
    display: flex;
    text-align: center;
    padding: 6px 0;
  }
  .info-item {
    flex-grow: 1;
    .info-item-label {
      color: #fff;
      font-size: 13px;
      margin-bottom: 6px;
    }
    .info-item-text {
      color: #fff;
      font-size: 17px;
      font-weight: bold;
    }
  }
  .filter-wrap {
    padding: 0 12px;
    color: #fff;
    .filter-lab {
      font-size: 14px; 
      padding: 10px 4px;
    }
    .van-field {
      font-size: 13px;
      background: #f8f7fc;
      padding: 6px 8px;
      border-radius: 6px;
    }
    .filter-symbol {
      text-align: center;
      line-height: 36px;
    }
    .amount-btn {
      width: 70px;
      padding: 0 2px;
      height: 36px;
      margin-left: 8px;
      border-radius: 6px;
    }
  }
  .game-icon-box {
    position: fixed;
    top: 205px;
    right: 0;
    width: 44px;
    height: 44px;
    padding: 8px;
    line-height: 44px;
    text-align: center;
    font-size: 28px;
    color: #226b96;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0px 1px 8px 0px #ccc;
    box-sizing: border-box;
    z-index: 99;
    .game-icon {
      display: block;
    }
  }
  .van-action-sheet__description {
    font-size: 16px;
    font-weight: bold;
    color: #ee0a24;
  }
}

.theme1 {
  .market {
    background: #fff;
  }
}
</style>
